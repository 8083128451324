import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardHeader = _resolveComponent("CardHeader")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, { class: "space-y-10" }, {
    default: _withCtx(() => [
      _createVNode(_component_CardHeader, {
        title: _ctx.$t('platform.role.new'),
        onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
      }, null, 8, ["title"]),
      _createVNode(_component_BaseTextInput, {
        modelValue: _ctx.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
        "data-dusk": "role-title",
        label: _ctx.$t('platform.role.title'),
        error: _ctx.errors.name ? _ctx.errors.name[0] : null
      }, null, 8, ["modelValue", "label", "error"]),
      _createElementVNode("div", null, [
        _createVNode(_component_BaseButton, {
          size: "large",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.save()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseButton, {
          size: "large",
          color: "ghost",
          class: "ml-4",
          onClick: _ctx.cancel
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}